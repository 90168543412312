import { motion } from "framer-motion"
import styled from "styled-components"

export const Container = styled(motion.div)`
    width: 100%;
    padding: 0 45px;
    margin: 0 auto;
    max-width: 1920px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 0 24px;
    }
`