exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-information-on-the-processing-of-personal-data-js": () => import("./../../../src/pages/information-on-the-processing-of-personal-data.js" /* webpackChunkName: "component---src-pages-information-on-the-processing-of-personal-data-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pl-informacja-o-przetwarzaniu-danych-osobowych-js": () => import("./../../../src/pages/pl/informacja-o-przetwarzaniu-danych-osobowych.js" /* webpackChunkName: "component---src-pages-pl-informacja-o-przetwarzaniu-danych-osobowych-js" */),
  "component---src-pages-rabat-15-na-cala-kolekcje-w-polsce-js": () => import("./../../../src/pages/rabat-15-na-cala-kolekcje-w-polsce.js" /* webpackChunkName: "component---src-pages-rabat-15-na-cala-kolekcje-w-polsce-js" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-all-products-page-jsx": () => import("./../../../src/templates/all-products-page.jsx" /* webpackChunkName: "component---src-templates-all-products-page-jsx" */),
  "component---src-templates-best-sellers-jsx": () => import("./../../../src/templates/best-sellers.jsx" /* webpackChunkName: "component---src-templates-best-sellers-jsx" */),
  "component---src-templates-catalogues-page-jsx": () => import("./../../../src/templates/catalogues-page.jsx" /* webpackChunkName: "component---src-templates-catalogues-page-jsx" */),
  "component---src-templates-collection-page-jsx": () => import("./../../../src/templates/collection-page.jsx" /* webpackChunkName: "component---src-templates-collection-page-jsx" */),
  "component---src-templates-conscious-page-jsx": () => import("./../../../src/templates/conscious-page.jsx" /* webpackChunkName: "component---src-templates-conscious-page-jsx" */),
  "component---src-templates-events-page-jsx": () => import("./../../../src/templates/events-page.jsx" /* webpackChunkName: "component---src-templates-events-page-jsx" */),
  "component---src-templates-exhibitions-page-jsx": () => import("./../../../src/templates/exhibitions-page.jsx" /* webpackChunkName: "component---src-templates-exhibitions-page-jsx" */),
  "component---src-templates-favourites-page-jsx": () => import("./../../../src/templates/favourites-page.jsx" /* webpackChunkName: "component---src-templates-favourites-page-jsx" */),
  "component---src-templates-furniture-care-page-jsx": () => import("./../../../src/templates/furniture-care-page.jsx" /* webpackChunkName: "component---src-templates-furniture-care-page-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-inform-pages-jsx": () => import("./../../../src/templates/inform-pages.jsx" /* webpackChunkName: "component---src-templates-inform-pages-jsx" */),
  "component---src-templates-legal-page-jsx": () => import("./../../../src/templates/legal-page.jsx" /* webpackChunkName: "component---src-templates-legal-page-jsx" */),
  "component---src-templates-material-page-jsx": () => import("./../../../src/templates/material-page.jsx" /* webpackChunkName: "component---src-templates-material-page-jsx" */),
  "component---src-templates-materials-archive-jsx": () => import("./../../../src/templates/materials-archive.jsx" /* webpackChunkName: "component---src-templates-materials-archive-jsx" */),
  "component---src-templates-new-arrivals-page-jsx": () => import("./../../../src/templates/new-arrivals-page.jsx" /* webpackChunkName: "component---src-templates-new-arrivals-page-jsx" */),
  "component---src-templates-products-archive-jsx": () => import("./../../../src/templates/products-archive.jsx" /* webpackChunkName: "component---src-templates-products-archive-jsx" */),
  "component---src-templates-sales-page-jsx": () => import("./../../../src/templates/sales-page.jsx" /* webpackChunkName: "component---src-templates-sales-page-jsx" */),
  "component---src-templates-search-page-jsx": () => import("./../../../src/templates/search-page.jsx" /* webpackChunkName: "component---src-templates-search-page-jsx" */),
  "component---src-templates-where-to-buy-page-jsx": () => import("./../../../src/templates/where-to-buy-page.jsx" /* webpackChunkName: "component---src-templates-where-to-buy-page-jsx" */),
  "component---src-templates-сontact-page-jsx": () => import("./../../../src/templates/сontact-page.jsx" /* webpackChunkName: "component---src-templates-сontact-page-jsx" */)
}

